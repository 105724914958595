<template>
    <div class="w-100 ps-0 pe-0 main-form-div">
        <div class="video-overlay">
            <div class="col-lg-3 ps-0 pe-0">
                <div class="card card-bg-color">
                    <div class="card-body ps-4 pe-4">
                        <div class="text-center mb-3">
                            <h5 class="text-white">Login Program Editor</h5>
                        </div>
                        <form @submit.prevent="handleLogin">
                            <div class="mb-4">
                                <label for="email" class="form-label mb-0 bold-label text-white">E-mail</label>
                                <input type="email" class="form-control" id="email" v-model="email" autocomplete="off"
                                    required>
                            </div>
                            <div class="mb-4 position-relative">
                                <label for="password" class="form-label mb-0 bold-label text-white">Password</label>
                                <input :type="passwordVisible ? 'text' : 'password'" class="form-control" id="password"
                                    v-model="password" required>
                                <i :class="passwordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"
                                    class="password-toggle-icon" @click="togglePasswordVisibility"></i>
                            </div>
                            <div class="text-center pt-3">
                                <button class="btn login-btn w-100" type="submit" :disabled="!email || !password">
                                    <i class="fa-solid fa-arrow-right-to-bracket me-2"></i>Login
                                </button>
                            </div>
                            <div v-if="error" class="text-danger text-center pt-3">
                                {{ error }}
                            </div>
                            <div class="text-end pt-4">
                                <a href="#">Forgot Password</a>
                            </div>
                        </form>
                        <div class="company-name text-white text-center">
                            <small>Copyright 2058, Example Corporation ©</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "vue";

export default {
    name: 'LoginPage',

    setup() {
        const email = ref('');
        const password = ref('');
        const error = ref(null);
        const passwordVisible = ref(false); // Şifre görünürlüğü için state
        const correctEmail = 'test@ver.us';
        const correctPassword = '1234';

        const router = useRouter();


        const handleLogin = () => {
            if (email.value === correctEmail && password.value === correctPassword) {
                console.log("Login successful:", { email: email.value });
                error.value = null;
                localStorage.setItem("loggedIn", "true"); // Giriş durumunu sakla
                router.push({ name: "Sessions" });
            } else {
                error.value = "Invalid email or password";
            }
        };

        //şifreyi görünür kılan fonksiyon
        const togglePasswordVisibility = () => {
            passwordVisible.value = !passwordVisible.value;
        };

        return {
            email,
            password,
            passwordVisible,
            error,
            handleLogin,
            togglePasswordVisibility,
        };
    },
};
</script>

<style scoped>
.main-form-div {
    height: 100dvh;
    background-color: #f0f0f0;
    background-image: url(https://i.makeagif.com/media/7-08-2019/UqQ3yI.gif);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center top;
    background-attachment: fixed;
}

.video-overlay {
    background-color: #479fdd36;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(33px);
}

.card-bg-color {
    background-color: #0D1117;
    border: none;
    border-radius: 0%;
    height: 100vh;
    padding-top: 12%;
    position: relative;
}

.company-name {
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.bold-label {
    font-weight: 600;
}

.login-btn {
    background-color: #5842D0;
    color: #FFF;
}

.login-btn:hover {
    background-color: #5842D0;
    color: #FFF;
}

.login-btn:disabled {
    background-color: #5842D0;
    color: #FFF;
    border: none;
}

input {
    border: none;
    background-color: transparent;
    border-bottom: 3px solid #9086c96b;
    border-radius: 0%;
    transition: all 300ms ease-in-out;
    box-shadow: none !important;
    color: #7c68f1 !important;
}

input:focus {
    background-color: transparent;
    border-bottom: 3px solid #7c68f1;
    border-radius: 0%;
}

.password-toggle-icon {
    position: absolute;
    top: 60%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #7c68f1;
    font-size: 1rem;
}
</style>