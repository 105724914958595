import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../HomePage.vue";
import ServiceDetail from "../ServiceDetail.vue";
import SessionPage from "../SessionPage.vue";
import TracksPage from "../TracksPage.vue";
import FacultyPage from "../FacultyPage.vue";
import PresentationsPage from "../PresentationsPage.vue";
import TopicsPage from "../TopicsPage.vue";
import HallsPage from "../HallsPage.vue";
import VenuesPage from "../VenuesPage.vue";
import LoginPage from "../auth/LoginPage.vue";

const routes = [
  { path: '/:pathMatch(.*)*', redirect: "/login" },
  { path: "/login", name: "Login", component: LoginPage, meta: { title: "Login" } },
  { path: "/home", name: "Home", component: HomePage, meta: { title: "Home" } },
  { path: "/sessions", name: "Sessions", component: SessionPage, meta: { title: "Sessions" } },
  { path: "/tracks", name: "Tracks", component: TracksPage, meta: { title: "Tracks" } },
  { path: "/faculty", name: "Faculty", component: FacultyPage, meta: { title: "Faculty" } },
  { path: "/presentations", name: "Presentations", component: PresentationsPage, meta: { title: "Presentations" } },
  { path: "/topics", name: "Topics", component: TopicsPage, meta: { title: "Topics" } },
  { path: "/halls", name: "Halls", component: HallsPage, meta: { title: "Halls" } },
  { path: "/venues", name: "Venues", component: VenuesPage, meta: { title: "Venues" } },
  { path: "/services/detail/:id", name: "ServiceDetail", component: ServiceDetail, meta: { title: "Service Detail" } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Giriş durumu kontrolü
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("loggedIn");

  if (to.name !== "Login" && !loggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
