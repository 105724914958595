import { reactive } from "vue";

const themeState = reactive({
  theme: "light", // Başlangıç teması
  toggleTheme() {
    // Temayı değiştir
    this.theme = this.theme === "light" ? "dark" : "light";

    // Body sınıfını güncelle
    document.body.classList.remove("light", "dark");
    document.body.classList.add(this.theme);
  },
});

// Uygulama yüklendiğinde body'ye başlangıç sınıfını ekle
document.body.classList.add(themeState.theme);

export { themeState };