<template>

    <div class="menu-main">

        <!-- Yan Menü -->
        <div class="w3-sidebar w3-bar-block w3-border-right" id="mySidebar">
            <i class="fa-regular fa-circle-xmark mobile-close-btn" onclick="closeSidebar()"></i>
            <div class="side-head mb-2">
                <img :src="sideBarImg" alt="foto" class="head-img">
                <h5 class="ps-2 mb-0">
                    {{ header }}
                </h5>
            </div>
            <div class="d-flex border-bottom ps-2 pb-2">
                <div class="side-user-main">
                    <img src="https://cdn-icons-png.flaticon.com/512/9187/9187604.png" alt="User-Image">
                </div>
                <div class="side-user-txt ps-2 pe-2">
                    <span>Birkan Can</span>
                    <p class="mb-0">
                        <small>Frontend Web Developer</small>
                    </p>
                </div>
            </div>
            <div class="side-link pt-2 pb-2 ps-2">
                <ul>
                    <li v-for="item in menuItems" :key="item.name">
                        <router-link :to="item.link" class="w3-bar-item w3-button" active-class="active-link">
                            <div class="d-flex justify-content-start align-items-center h-100">
                                <div class="icon-div ps-3">
                                    <i :class="[item.icon, 'me-3', 'icon-color']"></i>
                                </div>
                                <div class="ps-2">
                                    {{ item.name }}
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
                <button class="btn log-out-btn" @click="handleLogout">
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>
                    Logout
                </button>
            </div>
        </div>

        <!-- Ana Başlık ve Menü Açma Butonu -->

    </div>
</template>

<script>

import { useRouter } from 'vue-router';

export default {
    name: "LeftMenu",
    data() {
        return {
            // isSidebarOpen: true, // Sidebar başlangıçta açık
            header: "Program Editor",
            sideBarImg: "https://media.licdn.com/dms/image/v2/C560BAQFm6EBZkfL4zg/company-logo_200_200/company-logo_200_200/0/1630582502592/verus_event_management_it_solutions_logo?e=2147483647&v=beta&t=h8TyT4Y9kiUftIPUuMHJxdcLU_Noo-mAhuPi_Pphwnw",
            menuItems: [
                // { name: "Home", link: "/home", icon: "fa-solid fa-house" },
                { name: "Session", link: "/sessions", icon: "fa-solid fa-calendar-days" },
                { name: "Tracks", link: "/tracks", icon: "fa-solid fa-boxes-stacked" },
                { name: "Faculty", link: "/faculty", icon: "fa-regular fa-address-card" },
                { name: "Presentations", link: "/presentations", icon: "fa-solid fa-chart-simple" },
                { name: "Topics", link: "/topics", icon: "fa-regular fa-folder" },
                { name: "Halls", link: "/halls", icon: "fa-solid fa-door-open" },
                { name: "Venues", link: "/venues", icon: "fa-regular fa-building" },
            ],
        };
    },

    setup() {
        const router = useRouter();

        const handleLogout = () => {
            if (confirm("Are you sure you want to log out?")) {
                localStorage.removeItem("loggedIn"); // Giriş durumunu temizle
                router.replace({ name: "Login" });
            }
        };

        return { handleLogout };
    },

};


</script>

<style scoped>
.side-user-main img {
    width: 40px;
    height: 40px;
    object-fit: scale-down;
    border-radius: 50%;
    vertical-align: text-top;
}

.side-user-txt {
    width: 99%;
    line-height: 21px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.side-user-txt p {
    color: #5238C6;
}

.side-user-txt span {
    color: #173E68;
    font-weight: 600;
}

.icon-div {
    width: 14%;
    text-align: center;
    display: inline-block;
}

.icon-color {
    color: #5238C6;
}

.txt-div {
    width: 80%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.w3-button {
    text-decoration: none;
    color: #173E68;
    font-size: 16px;
    font-weight: 600;
}

.side-link ul {
    list-style-type: none;
    padding-left: 0;
}

ul>li {
    height: 39px;
}

.side-head {
    background: #E4EAF1;
    color: #172940;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.head-img {
    width: 23px;
}

.w3-sidebar {
    height: 100vh;
    width: 15%;
    background-color: #F0F4F9;
    position: fixed;
    display: block;
    transition: width 0.5s ease-in-out, display 0.5s ease-in-out;
    z-index: 1054;
}

.mobile-close-btn {
    display: none;
    color: #5238C6;
    font-size: 22px;
}

@media (max-width: 768px) {
    .w3-sidebar {
        display: none;
        width: 0;
    }

    .mobile-close-btn {
        display: inline-block;
        position: absolute;
        top: 17px;
        right: 27px;
    }
}

.active-link {
    border-right: 4px solid #5238c6;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    transition: 300ms ease-in-out;
    background-color: #d2e3f5;
    width: 100%;
    display: grid;
    align-content: center;
    height: 100%;
}

.log-out-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ff8484;
    color: #172940;
    border-radius: 0px !important;
    font-weight: 600;
}

.log-out-btn:hover {
    background-color: #ff0000;
    color: #E4EAF1;
}
</style>