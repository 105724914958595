<template>
  <nav class="custom-navi pt-3 pb-3 shadow">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 d-flex align-items-center">
          <a class="w3-button w3-teal w3-xlarge text-dark" onclick="toggleSidebar()"><i
              class="fa-solid fa-bars"></i></a>
          <h4 class="header d-block ms-4 mb-0">Faculty</h4>
        </div>
        <div class="col-lg-5">
          <div class="tooltip-div d-flex justify-content-end">
            <i class="fa-solid fa-magnifying-glass search-icon"></i>
            <input v-model="searchQuery" name="search" type="text" class="form-control me-3 cust-width">
            <!-- <input v-model="searchQuery" type="text" class="form-control me-3 cust-width"
                            placeholder="&#128269;" aria-label="Search" aria-describedby="basic-addon1"> -->
            <button class="btn add-item-btn me-3" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
              <i class="fa-solid fa-plus"></i>
            </button>
            <button class="btn day-night-btn btn-sm" @click="themeState.toggleTheme">
              <i v-if="themeState.theme === 'light'" class="fa-regular fa-moon"></i>
              <i v-if="themeState.theme === 'dark'" class="fa-regular fa-sun"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>


  <div class="col-lg-12 pt-3">

    <div v-if="items.length === 0" class="no-items-div">
      <p data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
        <i class="fa-solid fa-circle-exclamation me-2"></i>No items available.
      </p>
      <!-- <i class="fa-solid fa-object-group"></i> -->
    </div>
    <div v-else class="table-main">

      <table class="table table-striped table-responsive table-bordered">
        <thead>
          <tr>
            <th data-field="delete-update">Delete/Update</th>
            <th data-field="Title">Title</th>
            <th data-field="Profession">Profession</th>
            <th data-field="FirstName">First Name</th>
            <th data-field="LastName">Last Name</th>
            <th data-field="eMail">E-mail</th>
            <th data-field="Affiliation">Affiliation</th>
            <th data-field="Ciyt">City</th>
            <th data-field="Country">Country</th>
            <th data-field="Biography">Biography</th>
            <th data-field="ProfilePicture">Profile Picture</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredItems" :key="index" draggable="true" @dragstart="onDragStart(index)"
            @dragover.prevent @drop="onDrop(index)">
            <td>
              <button class="btn btn-sm delete-btn me-2" @click="deleteItem(index)">
                <i class="fa-solid fa-trash-can"></i>
              </button>
              <button class="btn btn-sm edit-btn" @click="editItem(index)" data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight">
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
            </td>
                <td>{{ item.Title }}</td>
                <td>{{ item.Profession }}</td>
                <td>{{ item.FirstName }}</td>
                <td>{{ item.LastName }}</td>
                <td>{{ item.eMail }}</td>
                <td>{{ item.Affiliation }}</td>
                <td>{{ item.City }}</td>
                <td>{{ item.Country }}</td>
                <td>{{ item.Biography }}</td>
                <td>{{ item.ProfilePicture }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <!-- rightSide field -->
  <div class="offcanvas offcanvas-end offcanvas-cust-width" tabindex="-1" id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header justify-content-between">
      <h4>Creating Item in Session</h4>
      <button class="btn save-item-btn" data-bs-dismiss="offcanvas" aria-label="Close" @click="handleSave">
        <i class="fa-solid fa-check"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <form action="">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 mb-2">
              <label for="title" class="form-label mb-0">Title</label>
              <input id="title" type="text" class="form-control canva-form-cust" v-model="Title">
            </div>
            <div class="col-lg-6 mb-2">
              <label for="profession" class="mb-0">Profession</label>
              <input id="proffesion" type="text" class="form-control canva-form-cust" v-model="Profession">
            </div>
            <div class="col-lg-6 mb-2">
              <label for="firstName" class="form-label mb-0">First Name</label>
              <input id="firstName" type="text" class="form-control canva-form-cust w-100" v-model="FirstName">
            </div>
            <div class="col-lg-6 mb-2">
              <label for="lastName" class="form-label mb-0">Last Name</label>
              <input id="lastName" type="text" class="form-control canva-form-cust w-100" v-model="LastName">
            </div>
            <div class="col-lg-6 mb-2">
              <label for="eMail" class="form-label mb-0">E-Mail</label>
              <input id="eMail" type="email" class="form-control canva-form-cust w-100" v-model="eMail">
            </div>
            <div class="col-lg-6 mb-2">
              <label for="affiliation" class="form-label mb-0">Affiliation</label>
              <input id="affiliation" type="text" class="form-control canva-form-cust w-100" v-model="Affiliation">
            </div>
            <div class="col-lg-6 mb-2">
              <label for="city" class="form-label mb-0">City</label>
              <input id="city" type="text" class="form-control canva-form-cust w-100" v-model="City">
            </div>
            <div class="col-md-6 mb-3">
              <label for="country" class="mb-0">Country</label>
              <select id="country" class="form-select canva-form-cust" v-model="Country">
                <option selected>Turkish Republic</option>
                <option value="">United States of America</option>
                <option value="">France</option>
                <option value="">New Zealand</option>
                <option value="">Germany</option>
              </select>
            </div>
            <div class="col-md-6 mb-3">
              <label for="biographyArea" class="form-label mb-0">Biography</label>
              <textarea id="biographyArea" class="form-control canva-form-cust" rows="5" v-model="Biography"></textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- rightSide field -->
</template>

<script>

import { themeState } from '@/assets/theme.js';

export default {
  name: "FacultyPage",

  data() {
    return {
      items: [], // Tabloda gösterilecek veriler
      searchQuery: '',
      draggedItemIndex: null,
      editingItemIndex: null,
      Title: '',
      Profession: '',
      FirstName: '',
      LastName: '',
      eMail: '',
      Affiliation: '',
      City: '',
      Country: '',
      Biography: ''
    };
  },

  methods: {
    // Draggable metotları
    onDragStart(index) {
      this.draggedItemIndex = index;
    },
    onDrop(index) {
      const draggedItem = this.items[this.draggedItemIndex];
      this.items.splice(this.draggedItemIndex, 1); // Dragged item'ı kaldır
      this.items.splice(index, 0, draggedItem); // Yeni konuma yerleştir
      this.draggedItemIndex = null; // Sıfırla
    },
    // Draggable metotları

    // handleSave metodunda bir butonda iki fonksiyon kontrol ediyoruz
    handleSave() {
      if (this.editingItemIndex !== null) {
        this.updateItem();
      } else {
        this.saveData();
      }
      this.resetForm();
    },

    // Yeni veri ekleme kısmı tüm form valueleri array liste pushluyoruz
    saveData() {
      const newItem = {
        id: this.items.length + 1,
        Title: this.Title,
        Profession: this.Profession,
        FirstName: this.FirstName,
        LastName: this.LastName,
        eMail: this.eMail,
        Affiliation: this.Affiliation,
        City: this.City,
        Country: this.Country,
        Biography: this.Biography
      };

      this.items.push(newItem);
    },

    // Tıkladığımız id ye göre veriyi formların içine gömüyor
    updateItem() {
      const updatedItem = {
        id: this.items[this.editingItemIndex].id,
        Title: this.Title,
        Profession: this.Profession,
        FirstName: this.FirstName,
        LastName: this.LastName,
        eMail: this.eMail,
        Affiliation: this.Affiliation,
        City: this.City,
        Country: this.Country,
        Biography: this.Biography
      };

      this.items.splice(this.editingItemIndex, 1, updatedItem);
      this.editingItemIndex = null;
    },


    // Düzenlenecek veriyi tableye geri basıyoruz
    editItem(index) {
      const item = this.items[index];
      this.Title = item.Title;
      this.Profession = item.Profession;
      this.FirstName = item.FirstName;
      this.LastName = item.LastName;
      this.eMail = item.eMail;
      this.Affiliation = item.Affiliation;
      this.City = item.City;
      this.Country = item.Country;
      this.Biography = item.Biography;
      this.editingItemIndex = index;
    },

    // İlgili satırı silen metot
    deleteItem(index) {
      const confirmDelete = confirm("Are you sure you want to delete this item?");
      if (confirmDelete) {
        this.items.splice(index, 1); // İlgili öğeyi sil
        // ID'leri yeniden sıralama
        this.items.forEach((item, idx) => {
          item.id = idx + 1; // Her öğeye yeni id ata
        });
      }
    },

    // Formu sıfırlama
    resetForm() {
      this.Draft = '';
      this.Title = '';
      this.Profession = '';
      this.FirstName = '';
      this.LastName = '';
      this.eMail = '';
      this.Affiliation = '';
      this.City = '';
      this.Country = '';
      this.Biography = '';
      this.editingItemIndex = null;
    },

  },

  computed: {
    filteredItems() {
      if (!this.searchQuery) {
        return this.items; // Arama yoksa tüm öğeleri döndür
      }
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      return this.items.filter(item =>
        Object.values(item).some(value =>
          String(value).toLowerCase().includes(lowerCaseQuery)
        )
      );
    }
  },

  setup() {
    return {
      themeState, // Reactive tema state'ini kullan
    };
  },

};
</script>

<style scoped>
.edit-btn {
  border: 1px solid #5238C6;
  background-color: #d3cbf7;
  color: #5238C6;
}

.edit-btn:hover {
  border: 1px solid #5238C6;
  background-color: #d3cbf7;
  color: #5238C6;
}

.delete-btn {
  border: 1px solid #da3636;
  background-color: #ffcfcf;
  color: #da3636;
}

.delete-btn:hover {
  border: 1px solid #da3636;
  background-color: #ffcfcf;
  color: #da3636;
}

.custom-navi {
  background-color: #FFF;
  color: #000;
  font-size: 22px;
  border-bottom: 1px solid #e6e6e6;
}

.w3-teal {
  cursor: pointer;
  color: #000 !important;
}

.add-item-btn,
.save-item-btn {
  background-color: #5842D0;
  color: #FFF;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
  font-size: 18px;
}

.save-item-btn:hover {
  background-color: #5842D0;
  color: #FFF;
}

.add-item-btn:hover {
  background-color: #5842D0;
  color: #FFF;
  transform: rotate(50deg);
}

.day-night-btn {
  background-color: #5842D0;
  color: #FFF;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 300ms ease-in-out;
  font-size: 18px;
}

.day-night-btn:hover {
  background-color: #5842D0;
  color: #FFF;
}

/* Form Styling */
.canva-form-cust {
  border: 1px solid #d9d9d9;
}

.canva-form-cust:focus {
  box-shadow: none !important;
  border: 1px solid #5238C6;
}

.offcanvas-cust-width {
  width: 45% !important;
}

/* Inputs */
.cust-width {
  width: 45px;
  height: 45px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  border-radius: 30px;
  z-index: 1039;
  background: transparent;
}

.cust-width:focus {
  width: 66%;
  background: #FFF;
  box-shadow: none !important;
  border: 1px solid #5238C6;
  padding-left: 22px !important;
}

/* Checkbox */
.form-check-input:checked {
  background-color: #5842D0;
  box-shadow: none !important;
}

.no-items-div {
  height: 75svh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  color: #5842D0;
}

/* Tablodaki satırların vurgulanması */
tr[draggable="true"] {
  cursor: move;
  border-top: 2px solid #7a6ad6;
}

th,
td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  min-width: 50px;
  max-width: 426px;
}

table {
  table-layout: auto;
  width: 100%;
}

/* th {
    min-width: 120px;
    max-width: fit-content;
}

td {
    min-width: 120px;
    max-width: fit-content;
} */

.table-main {
  width: 100%;
  overflow-y: auto;
}

.table-main::-webkit-scrollbar {
  height: 8px;
}

.table-main::-webkit-scrollbar-thumb {
  background-color: #7a6ad6;
  border-radius: 5px;
}

.table-main::-webkit-scrollbar-thumb:hover {
  background-color: #5842D0;
}

.search-icon {
  position: relative;
  top: 12px;
  left: 33px;
  color: #5238C6;
}
</style>