<template>
  <LeftMenu v-if="showMenuAndFooter" />
  <div class="container-fluid">
    <div id="main" class="row" :style="mainStyle">
      <div class="col-md-12 ps-0 pe-0">
        <!-- <HeaderPartial></HeaderPartial> -->
      </div>

      <div class="col-md-12 ps-0 pe-0">
        <div class="container-fluid">
          <div class="row">
            <router-view />
          </div>
        </div>

      </div>
    </div>
    <div class="fixed-bottom text-center">
      <FooterPartial v-if="showMenuAndFooter"></FooterPartial>
    </div>
  </div>

</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import LeftMenu from "./components/LeftMenu.vue";
import FooterPartial from "./components/FooterPart.vue"

export default {
  name: "App",
  components: {
    LeftMenu,
    FooterPartial
  },

  setup() {
    const route = useRoute();

    // Menü ve footer'ın görünürlüğü
    const showMenuAndFooter = computed(() => {
      const excludedRoutes = ["Login", "Register"]; // Bu rotalarda menü ve footer gözükmesin
      return !excludedRoutes.includes(route.name);
    });

    // #main'in padding-left stilini kontrol et
    const mainStyle = computed(() => ({
      "padding-left": showMenuAndFooter.value ? "15%" : "0", // Login ve Register'da padding-left kaldır
    }));

    return {
      showMenuAndFooter,
      mainStyle,
    };
  },

};
</script>

<style>
#main {
  padding-left: 15%;
  transition: padding-left 0.2s ease-in-out;
}

@media (max-width: 768px) {
  #main {
    padding-left: 0%;
  }
}
</style>
